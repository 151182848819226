import {AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_INFO, AUTH_LOGOUT} from '../actions/auth'
import axios from 'axios'

const state = {

    endpoints: {
        userInfo: "/en/api/rest-auth/user/",
        obtainJWT: "/en/api/auth/obtain_token/",
        refreshJWT: "/en/api/auth/refresh_token/",
        baseUrl: "/en/api/"
    },

    token: localStorage.getItem('user-token') || '',
    status: '',
    hasLoadedOnce: false,
    userInfo: localStorage.getItem('user_info'),
}

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    userInfo: state => state.userInfo,
}

const actions = {
    [AUTH_REQUEST]: ({commit,}, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST)
            axios.post(state.endpoints.obtainJWT, user)
                .then(resp => {
                    localStorage.setItem('user-token', resp.data.access)
                    axios.get(state.endpoints.userInfo, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + localStorage.getItem('user-token')
                        }
                    }).then(resp => {
                        localStorage.setItem('user_info', JSON.stringify(resp.data))
                        commit(AUTH_INFO, resp)
                        resolve(resp)
                    })
                    commit(AUTH_SUCCESS, resp)
                    resolve(resp)
                })
                .catch(err => {
                    commit(AUTH_ERROR, err)
                    localStorage.removeItem('user-token')
                    reject(err)
                })
        })
    },
    [AUTH_LOGOUT]: ({commit,}) => {
        return new Promise((resolve,) => {
            commit(AUTH_LOGOUT)
            localStorage.removeItem('user-token')
            localStorage.removeItem('metas_current')
            localStorage.removeItem('user_info')
            resolve()
        })
    }
}

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success'
        state.token = resp.data.access
        state.hasLoadedOnce = true
    },
    [AUTH_INFO]: (state, resp) => {
        state.userInfo = JSON.stringify(resp.data)
    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error'
        state.hasLoadedOnce = true
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = ''
        state.userInfo = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
