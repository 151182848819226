<template>
  <fusioncharts
      :type="type"
      :width="width"
      :height="height"
      :dataformat="dataFormat"
      :dataSource="dataSource"
      ref="fc"
  >
  </fusioncharts>
</template>

<script>
import Vue from 'vue';
import VueFusionChartsComponent from 'vue-fusioncharts/component';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import Candy from 'fusioncharts/themes/fusioncharts.theme.candy';
import Gammel from 'fusioncharts/themes/fusioncharts.theme.gammel';

const vueFusionCharts = VueFusionChartsComponent(FusionCharts, Charts, Candy, Gammel);

Vue.component('fusioncharts', vueFusionCharts);

export default {

  name: "FusionScatter",

  props: {
    chartConfig: {
      type: Object
    },
    chartData: {
      type: Array
    }
  },

  data: () => ({
    type: "scatter",
    width: "100%",
    height: "500",
    dataFormat: "json",
    dataSource: {
      chart: {
        exportEnabled: 1,
        caption: '',
        subcaption: '',
        xaxisname: '',
        yaxisname: '',
        theme: localStorage.getItem('dark') === 'true' ? 'candy' : 'gammel',
        bgColor: localStorage.getItem('dark') === 'true' ? "#1e1e1e" : "#ffffff",
        regressionLineColor: localStorage.getItem('dark') === 'true' ? "#ffffff" : "#222222",
        formatNumberScale: 0,
        formatNumber: 0
      },
      dataset: [
        {
          seriesname: '',
          showregressionline: "1",
          data: []
        }
      ]
    },
  }),

  computed: {
    currentTheme: function () {
      return this.$vuetify.theme.dark ? 'candy' : 'gammel'
    }
  },

  watch: {
    currentTheme() {
      this.onThemeChanged()
    },
    chartData() {
      this.setDataSource()
    }
  },

  methods: {

    onThemeChanged: function () {
      this.$refs.fc.chartObj.setChartAttribute("theme", this.currentTheme)
      this.$refs.fc.chartObj.setChartAttribute("bgColor", this.currentTheme === 'candy' ? '#1e1e1e' : '#ffffff');
      this.$refs.fc.chartObj.setChartAttribute("regressionLineColor", this.currentTheme === 'candy' ? '#ffffff' : '#222222');
    },

    setDataSource: function () {
      this.dataSource.dataset[0].seriesname = this.chartConfig.seriesname
      this.dataSource.dataset[0].data = this.chartData
      this.dataSource.chart.caption = this.chartConfig.caption
      this.dataSource.chart.subcaption = this.chartConfig.subcaption
      this.dataSource.chart.xaxisname = this.chartConfig.xaxisname
      this.dataSource.chart.yaxisname = this.chartConfig.yaxisname
      this.dataSource.chart.anchorBorderColor = this.chartConfig.anchorBorderColor
      this.dataSource.chart.anchorSides = this.chartConfig.anchorSides
      this.dataSource.chart.anchorBgColor = this.chartConfig.anchorBgColor
    }

  },

  created() {
    this.setDataSource()
  }

}
</script>

<style scoped>

</style>