<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-if="ifAuthenticated"
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
    >

      <NavBar :key="language"/>

    </v-navigation-drawer>
    <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="blue darken-3"
        dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title
          style="width: 300px"
          class="ml-0 pl-4"
      >
        <span class="hidden-sm-and-down"><a class="logo" title="MetaAnalysis"
                                            :href="$i18n.locale">MetaAnalysis</a></span>
      </v-toolbar-title>
      <v-spacer/>

      <div class="theme-switch mt-6 mr-5">
        <v-switch
            @change="changeTheme"
            v-model="darkTheme"
            color="blue darken-4"
            prepend-icon="mdi-white-balance-sunny"
            append-icon="mdi-weather-night"
        ></v-switch>
      </div>

      <div v-if="userInfo" class="user-name">
        <v-icon>mdi-account</v-icon>
        <v-card-text tag="span" v-text="userInfo['first_name'] + ' ' + userInfo['last_name']"></v-card-text>
      </div>

      <div class="locale-changer">
        <span class="lang-flag" v-if="language==='de'"><img class="flag"
                                                            src="/static/de.svg"
                                                            alt="Germany Flag"></span>
        <span class="lang-flag" v-else><img class="flag"
                                            src="/static/gb.svg"
                                            alt="Germany Flag"></span>
        <select :class="language" @change="changeLang(language)" v-model="language">
          <option
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              :value="lang"
              :selected="language"

          >{{ lang }}
          </option
          >
        </select>
      </div>

    </v-app-bar>
    <v-main>
      <v-container style="max-width: 100%;">
        <v-row>
          <v-col>
            <router-view :key="language"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

// import {mapGetters, mapState} from 'vuex';

import NavBar from "./components/NavBar";
import store from '@/store'

export default {
  name: 'App',

  components: {
    NavBar,
  },
  props: {
    source: String,
  },
  computed: {
    ifAuthenticated: {
      get() {
        return store.getters.isAuthenticated;
      },
    },
    userInfo: {
      get() {
        return JSON.parse(store.getters.userInfo);
      }
    }
  },
  data: () => ({
    dialog: false,
    drawer: null,
    language: '',
    langs: ['de', 'en'],
    darkTheme: localStorage.getItem('dark') === 'true'
  }),

  methods: {
    changeTheme() {
      localStorage.setItem('dark', this.darkTheme)
      this.$vuetify.theme.dark = this.darkTheme
    },
    changeLang(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);
      this.$router.push({
            params: {lang: locale}
          }
      );
    },
    setDefaultLang() {
      if (!localStorage.getItem("lang")) {
        localStorage.setItem("lang", 'en');
      }

      this.language = localStorage.getItem("lang");
      this.$i18n.locale = this.language;
    }
  },
  created() {
    this.setDefaultLang()
  }

}
</script>

<style>
.locale-changer {
  width: 100px;
  line-height: 12px;
}

.locale-changer span {
  width: 25px;
  display: inline-block;
  padding-top: 2px;
  float: left;
}

.locale-changer select {
  width: 50px;
  font-size: 17px;
  -moz-appearance: listbox;
  -webkit-appearance: listbox;
  display: inline-block;
}

.locale-changer select:hover {
  cursor: pointer;
}

.locale-changer select:focus {
  border: none !important;
}


.v-application a.logo {
  text-decoration: none;
  color: #fff;
}

.v-application a.logo:hover {
  text-decoration: underline;
}

.user-name .v-card__text {
  padding-left: 5px;
}
</style>