import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '@/router'
import store from '@/store'

import VueI18n from 'vue-i18n';
import messages from './lang';


Vue.config.productionTip = false

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
});

Vue.use(VueAxios, axios)


new Vue({
  vuetify,
  router,
  store,
  i18n,
  components: { App },
  render: h => h(App)
}).$mount('#app')
