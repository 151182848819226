<script>
    import {Line} from 'vue-chartjs'

    export default {
        name: 'ChartLine',
        extends: Line,
        data: () => ({
            chartdata: {
                labels: ['Январь', 'Февраль', 'Март', 'Апрель'],
                datasets: [
                    {
                        label: 'Данные 1',
                        backgroundColor: '#f87979',
                        data: [40, 20, 60, 10, 5, 80],
                        fill: false
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false

            }
        }),

        mounted() {
            this.renderChart(this.chartdata, this.options)
        }
    }
</script>

<style scoped>

</style>