<template>
  <div>
    <fusioncharts
        :type="type"
        :width="width"
        :height="height"
        :dataFormat="dataFormat"
        :dataSource="dataSource"
        ref="fc"
    ></fusioncharts>
  </div>
</template>
<script>

import Vue from 'vue';
import VueFusionChartsComponent from 'vue-fusioncharts/component';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import AngularGauge from 'fusioncharts/fusioncharts.widgets';
import Candy from 'fusioncharts/themes/fusioncharts.theme.candy';
import Gammel from 'fusioncharts/themes/fusioncharts.theme.gammel';

const vueFusionCharts = VueFusionChartsComponent(FusionCharts, Charts, AngularGauge, Candy, Gammel);

Vue.component('fusioncharts', vueFusionCharts);


export default {

  name: "FusionAngularGauge",

  props: {
    dvalue: {
      type: Number
    },
    q_interpretation: {
      type: String
    }
  },

  computed: {
    currentTheme: function () {
      return this.$vuetify.theme.dark ? 'candy' : 'gammel'
    }
  },

  watch: {
    currentTheme() {
      this.onThemeChanged()
    },
  },

  methods: {
    onThemeChanged: function () {
      this.$refs.fc.chartObj.setChartAttribute("theme", this.currentTheme);
      this.$refs.fc.chartObj.setChartAttribute("bgColor", this.currentTheme === 'candy' ? '#1e1e1e' : '#ffffff');
    },

    setDValues() {
      if (this.q_interpretation === 'rather-small') {
        this.dataSource.dials.dial[0].value = this.dvalue - 0.1
        this.dataSource.dials.dial[1].value = this.dvalue - 0.1 / 2.0
        this.dataSource.dials.dial[2].value = this.dvalue + 0.1
        this.dataSource.dials.dial[3].value = this.dvalue + 0.1 / 2.0
      } else if (this.q_interpretation === 'acceptable') {
        this.dataSource.dials.dial[0].value = this.dvalue - 0.05
        this.dataSource.dials.dial[1].value = this.dvalue - 0.05 / 2.0
        this.dataSource.dials.dial[2].value = this.dvalue + 0.05
        this.dataSource.dials.dial[3].value = this.dvalue + 0.05 / 2.0
      } else if (this.q_interpretation === 'high') {
        this.dataSource.dials.dial[0].value = this.dvalue
        this.dataSource.dials.dial[1].value = this.dvalue - 0.05 / 2.0
        this.dataSource.dials.dial[2].value = this.dvalue
        this.dataSource.dials.dial[3].value = this.dvalue + 0.05 / 2.0
      } else {
        this.dataSource.dials.dial[0].value = this.dvalue
        this.dataSource.dials.dial[1].value = this.dvalue
        this.dataSource.dials.dial[2].value = this.dvalue
        this.dataSource.dials.dial[3].value = this.dvalue
      }
      this.dataSource.dials.dial[4].value = this.dvalue
    }
  },

  created() {
    this.setDValues()
  },

  data: () => ({
    type: "angulargauge",
    width: "360",
    height: "260",
    dataFormat: "json",
    dialog: false,
    dataSource: {
      chart: {
        gaugeOuterRadius: "160",
        gaugeOriginY: "210",
        gaugeOriginX: "180",
        gaugeStartAngle: "210",
        gaugeEndAngle: "-30",
        lowerlimit: "-0.4",
        upperlimit: "1.2",
        showvalue: "0",
        theme: localStorage.getItem('dark') === 'true' ? 'candy' : 'gammel',
        bgColor: localStorage.getItem('dark') === 'true' ? "#1e1e1e" : "#ffffff",
        showtooltip: "0",
        majortmnumber: "9",
        bgImage: "/static/bgchartangular_" + localStorage.getItem('lang') + ".png",
        bgImageDisplayMode: "Fit",
        bgImageValign: "middle",
        bgImageHalign: "middle",
        showTickMarks: "0",
        pivotFillColor: "#42b983",
        pivotRadius: "7"
      },
      colorrange: {
        color: [
          {
            "alpha": "0",
            "minValue": "-0.4",
            "maxValue": "-0.2",
            "code": "#F2726F"
          },
          {
            "alpha": "0",
            "minValue": "-0.2",
            "maxValue": "0",
            "code": "#F2726F"
          }, {
            "alpha": "0",
            "minValue": "0",
            "maxValue": "0.2",
            "code": "#FFC533"
          }, {
            "alpha": "0",
            "minValue": "0.2",
            "maxValue": "0.4",
            "code": "#62B58F"
          }, {
            "alpha": "0",
            "minValue": "0.4",
            "maxValue": "0.6",
            "code": "#62B58F"
          }, {
            "alpha": "0",
            "minValue": "0.6",
            "maxValue": "0.8",
            "code": "#62B58F"
          }, {
            "alpha": "0",
            "minValue": "0.8",
            "maxValue": "1.0",
            "code": "#62B58F"
          }, {
            "alpha": "0",
            "minValue": "1.0",
            "maxValue": "1.2",
            "code": "#62B58F"
          }
        ]
      },
      dials: {
        dial: [
          {
            value: '',
            bgAlpha: "10",
            bgcolor: "fed5d2",
            bordercolor: "fff1f0",
            borderalpha: "90",
            basewidth: "6",
            topwidth: "2",
            borderthickness: "1",
          },
          {
            value: '',
            bgAlpha: "10",
            bgcolor: "fed5d2",
            bordercolor: "fff1f0",
            borderalpha: "90",
            basewidth: "6",
            topwidth: "2",
            borderthickness: "1",
          },
          {
            value: '',
            bgAlpha: "10",
            bgcolor: "fed5d2",
            bordercolor: "fff1f0",
            borderalpha: "90",
            basewidth: "6",
            topwidth: "2",
            borderthickness: "1",
          },
          {
            value: '',
            bgAlpha: "10",
            bgcolor: "fed5d2",
            bordercolor: "fff1f0",
            borderalpha: "90",
            basewidth: "6",
            topwidth: "2",
            borderthickness: "1",
          },
          {
            value: '',
            bgcolor: "ff0000",
            bordercolor: "000000",
            borderalpha: "90",
            basewidth: "8",
            topwidth: "2",
            borderthickness: "1",
          }
        ]
      }
    }
  }),

}
</script>

<style>
text[y="193"] {
  display: none;
}

svg > g[class^="raphael-group-"] > text {
  display: none;
}
</style>