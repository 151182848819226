<template>
    <div>
        Logout
    </div>
</template>

<script>
    import {AUTH_LOGOUT} from "../../store/actions/auth";

    export default {
        name: "Logout",
        mounted() {
            this.logout();
        },
        methods: {
            logout: function () {
                this.$store.dispatch(AUTH_LOGOUT)
                    .then(() => {
                        this.$router.push('/' + this.$i18n.locale + '/Login')
                    })
            }
        },
    };
</script>