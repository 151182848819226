<script>
    import {Radar} from 'vue-chartjs'

    export default {
        name: 'ChartRadar',
        extends: Radar,
        data: () => ({
            chartdata: {
                labels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль'],
                datasets: [
                    {
                        label: 'Данные 1',
                        backgroundColor: 'rgba(255, 0, 255, 0.1)',
                        data: [65, 59, 90, 81, 56, 55, 40],
                    },
                    {
                        label: 'Данные 2',
                        backgroundColor: 'rgba(0, 255, 0, 0.1)',
                        data: [28, 48, 40, 19, 96, 27, 100],
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scale: {
                    angleLines: {
                        display: false
                    },
                    ticks: {
                        suggestedMin: 50,
                        suggestedMax: 100
                    }
                }

            }
        }),

        mounted() {
            this.renderChart(this.chartdata, this.options)
        }
    }
</script>

<style scoped>

</style>