<template>
  <v-container fluid>
    <v-row dense>
      <v-col
          cols="12"
          sm="12">
        <v-card>
          <v-card-title>{{ $t('Charts') }}</v-card-title>
          <v-card-text>
            <SelectMetas @metas_selected="getChartsData"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
          cols="12"
          sm="12">
        <v-card id="factors-count-domains">
          <FusionColumn2d :chartConfig="chartDomainsFactorsConfig" :chartData="chartDomainsFactorsData"/>
          <v-card-actions>
            <v-btn
                icon
                class="barometer-to-png"
                @click="getPngFromChart(id='factors-count-domains')"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
          cols="12"
          sm="12">
        <v-card id="d-NMetas">
          <FusionScatter :chartConfig="chartDNmetasConfig" :chartData="chartDNmetasData"/>
          <v-card-actions>
            <v-btn
                icon
                class="barometer-to-png"
                @click="getPngFromChart(id='d-NMetas')"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
          cols="12"
          sm="12">
        <v-card id="d-NEffect">
          <FusionColumn2d :chartConfig="chartDNeffectConfig" :chartData="chartDNeffectData"/>
          <v-card-actions>
            <v-btn
                icon
                class="barometer-to-png"
                @click="getPngFromChart(id='d-NEffect')"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
          cols="12"
          sm="12">
        <v-card id="d-NFactors">
          <FusionColumn2d :chartConfig="chartDNfactorsConfig" :chartData="chartDNfactorsData"/>
          <v-card-actions>
            <v-btn
                icon
                class="barometer-to-png"
                @click="getPngFromChart(id='d-NFactors')"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
          cols="12"
          sm="12">
        <v-card id="q-NFactors">
          <FusionColumn2d :chartConfig="chartQNfactorsConfig" :chartData="chartQNfactorsData"/>
          <v-card-actions>
            <v-btn
                icon
                class="barometer-to-png"
                @click="getPngFromChart(id='q-NFactors')"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
          cols="12"
          sm="12">
        <v-card id="d-Year">
          <FusionScatter :chartConfig="chartDYearConfig" :chartData="chartDYearData"/>
          <v-card-actions>
            <v-btn
                icon
                class="barometer-to-png"
                @click="getPngFromChart(id='d-Year')"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
          cols="12"
          sm="12">
        <v-card id="d-q">
          <FusionScatter :chartConfig="chartDQConfig" :chartData="chartDQData"/>
          <v-card-actions>
            <v-btn
                icon
                class="barometer-to-png"
                @click="getPngFromChart(id='d-q')"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as htmlToImage from "html-to-image";
import FusionColumn2d from "../charts/FusionColumn2d";
import FusionScatter from "../charts/FusionScatter";
import SelectMetas from "../SelectMetas";
import {apiService} from "../../common/apiService";
import {i18n} from "@/main.js";


export default {

  name: "ResultCharts",

  components: {
    FusionScatter,
    FusionColumn2d,
    SelectMetas
  },

  data: () => ({
    language: localStorage.getItem('lang'),

    chartDNmetasConfig: {
      caption: i18n.t("d to N-Metas"),
      subcaption: "",
      xaxisname: "d",
      yaxisname: i18n.t("N-Metas"),
      seriesname: i18n.t("d, N-Metas"),
      anchorBorderColor: "#1565C0",
      anchorBgColor: "#ffffff",
      anchorSides: 3
    },
    chartDNmetasData: [],

    chartDNeffectConfig: {
      type: 'column2d',
      caption: i18n.t("d to N-Effect"),
      subcaption: "",
      xaxisname: "d",
      yaxisname: i18n.t("N-Effect"),
      showValues: 0,
      labelDisplay: 'AUTO',
      labelStep: 2
    },
    chartDNeffectData: [],

    chartDNfactorsConfig: {
      type: 'column2d',
      caption: i18n.t("d to N-Factors"),
      subcaption: "",
      xaxisname: "d",
      yaxisname: i18n.t("N-Factors"),
      showValues: 0,
      labelDisplay: 'AUTO',
      labelStep: 1
    },
    chartDNfactorsData: [],

    chartQNfactorsConfig: {
      type: 'column2d',
      caption: i18n.t("q to N-Factors"),
      subcaption: "",
      xaxisname: "q",
      yaxisname: i18n.t("N-Factors"),
      showValues: 0,
      labelDisplay: 'AUTO',
      labelStep: 1
    },
    chartQNfactorsData: [],

    chartDYearConfig: {
      caption: i18n.t("d to Year"),
      subcaption: "",
      xaxisname: i18n.t("Year"),
      yaxisname: "d",
      seriesname: i18n.t("Year, d"),
      anchorBorderColor: "#e91e63",
      anchorBgColor: "#ffffff",
      anchorSides: 4
    },
    chartDYearData: [],

    chartDomainsFactorsConfig: {
      type: 'column3d',
      caption: i18n.t("Domains to Factors Count"),
      subcaption: "",
      xaxisname: i18n.t("Domains"),
      yaxisname: i18n.t("Factors count"),
      showValues: 1,
    },
    chartDomainsFactorsData: [],

    chartDQConfig: {
      caption: i18n.t("d to q"),
      subcaption: "",
      xaxisname: "d",
      yaxisname: "q",
      seriesname: "d, q",
      anchorBorderColor: "#1565C0",
      anchorBgColor: "#86C6F4",
      anchorSides: 4
    },
    chartDQData: [],
  }),

  methods: {


    getChartsData(id) {
      let endpoint = "en/api/charts-data-get/" + id + "/"
      apiService(endpoint, 'get').then((response) => {
        this.chartDNmetasData = response.data.data_d_nmetas.data
        this.chartDNmetasConfig.subcaption = i18n.t("Metas count: ") + response.data.data_d_nmetas.count
        this.chartDNeffectData = response.data.data_d_neffect.data
        this.chartDNeffectConfig.subcaption = i18n.t("Effect count: ") + response.data.data_d_neffect.count
        this.chartDNfactorsData = response.data.data_d_nfactors.data
        this.chartDNfactorsConfig.subcaption = i18n.t("Factors count: ") + response.data.data_d_nfactors.count
        this.chartQNfactorsData = response.data.data_q_nfactors.data
        this.chartQNfactorsConfig.subcaption = i18n.t("Factors count: ") + response.data.data_q_nfactors.count
        this.chartDYearData = response.data.data_d_year.data
        this.chartDYearConfig.subcaption = i18n.t("Metas count: ") + response.data.data_d_year.count
        this.chartDomainsFactorsData = i18n.locale === 'en' ?
            response.data.data_domains_factors.data_list_en :
            response.data.data_domains_factors.data_list_de
        this.chartDomainsFactorsConfig.subcaption = i18n.t("Factors count: ") + response.data.data_domains_factors.count
        this.chartDQData = response.data.data_d_q.data
        this.chartDQConfig.subcaption = i18n.t("Factors count: ") + response.data.data_d_q.count
        this.loading = false
      })
    },

    getPngFromChart(id) {
      htmlToImage
          .toBlob(document.getElementById(id))
          .then(function (blob) {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = "Chart-" + id + ".png";
            a.click();
          });
    },

  },

}
</script>
<style scoped>

</style>