<template>
  <v-data-table
      :headers="headers"
      :items="dataset"
      class="elevation-1"
      v-model="selected"
      show-select
      :search="search"
      :key="this.editedItem.id"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          {{ $t("DataSet") }}
          <v-btn color="primary" dark class="mb-2" @click="calculate">{{ $t("Calculate") }}</v-btn>
        </v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>


        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">{{ $t("New Item") }}</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                        v-model="editedItem.domain"
                        chips
                        deletable-chips
                        :items="domains"
                        label="Domain"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                        v-model="editedItem.factor"
                        chips
                        deletable-chips
                        :items="factors"
                        label="Factor"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.vl" label="VL"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.author" label='Author'></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.year" label="Year"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.n_metas" label="N-Metas"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.n_effect" label="N-Effect"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.d" label="d"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.description"
                                  label="Description"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <tr>
        <td>
          <v-select
              v-model="domain"
              chips
              deletable-chips
              multiple
              :items="domain_names"
              label="Domain"
          ></v-select>
        </td>
        <td>
          <v-select
              v-model="factor"
              chips
              deletable-chips
              multiple
              :items="factor_names"
              label="Factor"
          ></v-select>
        </td>
        <td>
          <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
          ></v-text-field>
        </td>
      </tr>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!--            <v-btn color="primary" @click="initialize">Reset</v-btn>-->
      <p style="text-align: center;">Loading... Please wait</p>
    </template>
  </v-data-table>

</template>

<script>
import {i18n} from "@/main.js";
import {apiService} from "../../common/apiService";

export default {
  name: 'InputDataSet',

  data: () => ({
    auth_headers: [],
    dialog: false,
    domain: [],
    domains: [],
    domain_names: [],
    factor: [],
    factors: [],
    factor_names: [],
    dataset: [],
    selected: [],
    search: '',
    editedIndex: -1,
    editedItem: {
      domain: '',
      factor: '',
      vl: '',
      author: '',
      year: '',
      n_metas: 0,
      n_effect: 0,
      d: 0,
      description: '',
    },
    defaultItem: {
      domain: '',
      factor: '',
      vl: '',
      author: '',
      year: '',
      n_metas: 0,
      n_effect: 0,
      d: 0,
      description: '',
    },
    userid: 0,
  }),

  computed: {
    headers() {
      return [
        {text: 'id', value: 'id'},
        {
          text: 'Domain',
          align: 'left',
          value: 'domain_' + this.$i18n.locale,
          filter: (value) => {
            if (!this.domain.length) return true
            // return true until issue is resolved
            return this.domain.find(n => n === value)
          },
        },
        {
          text: i18n.t("Factor"),
          align: 'left',
          value: 'factor_' + this.$i18n.locale,
          filter: (value) => {
            if (!this.factor.length) return true
            // return true until issue is resolved
            return this.factor.find(n => n === value)
          },
        },
        {text: 'VL', value: 'vl'},
        {text: this.$i18n.t('Author'), value: 'author'},
        {text: this.$i18n.t('Year'), value: 'year'},
        {text: this.$i18n.t('N-Metas'), value: 'n_metas'},
        {text: this.$i18n.t('N-Effect'), value: 'n_effect'},
        {text: 'D', value: 'd'},
        {text: this.$i18n.t('Weight'), value: 'weight', class: 'color-green'},
        {text: this.$i18n.t('Probability'), value: 'probability', class: 'color-green'},
        {text: this.$i18n.t('Description'), value: 'description'},
        {text: this.$i18n.t('Actions'), value: 'action', sortable: false},
      ]
    },

    formTitle() {
      return this.editedIndex === -1 ? this.$i18n.t('New Item') : this.$i18n.t('Edit Item')
    },
  },

  created() {
    this.getDataItems()
    this.getUserId()
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
    ,
  },

  methods: {

    calculate() {
      this.dataset = [];
      let endpoint = "en/api/calculate/";
      apiService(endpoint).then((response) => {
        // this.dataset = response.data;
        // eslint-disable-next-line no-console
        console.log(response)
        this.getDataItems()
      })
    },

    getUserId() {
      let endpoint = "en/api/rest-auth/user/";
      apiService(endpoint).then((response) => {
        // eslint-disable-next-line no-console
        console.log(response.data.pk)
        this.userid = response.data.pk;
      })
    },

    getDataItems() {
      let endpoint = "en/api/datasets/";
      apiService(endpoint).then((response) => {
        this.dataset = response.data;
      })

      let endpoint_f = "en/api/factors/";
      apiService(endpoint_f).then(response => {
        // eslint-disable-next-line no-console
        for (let i = 0; i < response.data.length; i++) {
          this.factors.push({
            text: response.data[i]['factor_' + this.$i18n.locale],
            value: response.data[i]['id']
          })

          this.factor_names.push(response.data[i]['factor_' + this.$i18n.locale])
        }

      })

      let endpoint_d = "en/api/domains/";
      apiService(endpoint_d).then((response) => {
            for (let i = 0; i < response.data.length; i++) {

              this.domains.push({
                text: response.data[i]['domain_' + this.$i18n.locale],
                value: response.data[i]['id']
              })

              this.domain_names.push(response.data[i]['domain_' + this.$i18n.locale])
            }
            // eslint-disable-next-line no-console
            console.log(this.domains)
          }
      )
    },

    editItem(item) {
      this.editedIndex = this.dataset.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    }
    ,

    deleteItem(item) {
      const index = this.dataset.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.dataset.splice(index, 1)

      let endpoint = "en/api/datasets/" + item.id + "/";
      apiService(endpoint, 'delete').then((response) => {
        // eslint-disable-next-line no-console
        console.log(response)
      })

    }
    ,

    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    }
    ,

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.dataset[this.editedIndex], this.editedItem)

        let endpoint = "en/api/datasets/" + this.editedItem.id + "/";
        apiService(endpoint, 'patch', this.editedItem).then((response) => {
          // eslint-disable-next-line no-console
          console.log(response)
          this.getDataItems()
        })

      } else {
        this.dataset.push(this.editedItem)

        let endpoint = "en/api/datasets/";
        // eslint-disable-next-line no-console
        console.log(this.editedItem)
        this.editedItem.owner = this.userid
        apiService(endpoint, 'post', this.editedItem).then((response) => {
          // eslint-disable-next-line no-console
          console.log(response)
          this.getDataItems()
        })

      }
      this.close()
    }
    ,
  }
  ,

}
</script>

<style>
th.color-green span {
  color: #4cae4c !important;
  font-weight: bold !important;

}
</style>