<template>
  <v-card>
    <v-card-title>
      {{ $t('Metas Duplicates') }}
      <v-spacer></v-spacer>
      <v-container fluid>
        <v-row dense>
          <v-col
              class="d-flex"
              cols="12"
              sm="6"
          >
            <SelectMetas @metas_selected="getDataItems"/>
          </v-col>
          <v-col
              class="d-flex"
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                outlined
                dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn :disabled="!duplicates.length" color="warning" class="mb-2 mr-2"
                   @click="deleteDuplicatesDialog('first')">
              {{ $t('Delete') + ' ' + duplicates_sum + $t(' duplicates ') + $t('(keep first)') }}
            </v-btn>
            <v-btn :disabled="!duplicates.length" color="warning" class="mb-2 mr-2"
                   @click="deleteDuplicatesDialog('last')">
              {{ $t('Delete') + ' ' + duplicates_sum + $t(' duplicates ') + $t('(keep last)') }}
            </v-btn>
          </v-col>

          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h5">{{
                  duplicates_sum + $t(' dublicates found, are you sure to delete them?')
                }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{ $t('Cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteConfirm">{{ $t('Yes, delete') }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-row>
      </v-container>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="formattedMetasDuplicates"
        :search="search"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 500, -1],
        }"
        :loading="loading"
        loading-text="Loading... Please wait"
        :sort-by="['Faktor_E', 'Autor', 'Jahr']"
        multi-sort
    ></v-data-table>
  </v-card>
</template>

<script>
import {i18n} from "@/main.js";
import {apiService} from "../../common/apiService";
import SelectMetas from "../SelectMetas";

export default {
  name: "MetasDuplicates",

  components: {
    SelectMetas
  },

  data() {
    return {
      search: '',
      headers: [
        {text: 'Id', align: 'start', value: 'id'},
        {text: i18n.t('Domäne_E'), value: 'Domäne_E'},
        {text: i18n.t('Domäne_D'), value: 'Domäne_D'},
        {text: i18n.t('Faktor_E'), value: 'Faktor_E'},
        {text: i18n.t('Faktor_D'), value: 'Faktor_D'},
        {text: i18n.t('VL'), value: 'VL'},
        {text: i18n.t('Autor'), value: 'Autor'},
        {text: i18n.t('Jahr'), value: 'Jahr'},
        {text: i18n.t('N-Metas'), value: 'N-Metas'},
        {text: i18n.t('N-Effekte'), value: 'N-Effekte'},
        {text: i18n.t('d'), value: 'd'},
        {text: i18n.t('Beschreibung'), value: 'Beschreibung'},
      ],
      duplicates: [],
      duplicates_sum: 0,
      loading: true,
      language: i18n.locale,
      dialogDelete: false,
      keep: '',
    }
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {

    deleteDuplicatesDialog(keep) {
      this.dialogDelete = true
      this.keep = keep
    },

    deleteConfirm() {
      this.deleteDuplicates(this.keep)
    },

    closeDelete() {
      this.dialogDelete = false
      this.keep = ''
    },

    getDataItems(id) {
      this.duplicates = []
      this.loading = true
      let endpoint = "en/api/metas-input-get/" + id + "/"
      apiService(endpoint, 'get').then((response) => {
        this.duplicates = response.data.duplicates
        this.duplicates_sum = response.data.duplicates_sum
        this.loading = false
      })
    },

    deleteDuplicates(keep) {
      this.dialogDelete = false
      this.loading = true
      let endpoint = "en/api/metas-duplicates-remove/" + parseInt(localStorage.getItem('metas_current')) + "/"
      apiService(endpoint, 'post', {
        keep: keep
      }).then(() => {
        this.getDataItems(parseInt(localStorage.getItem('metas_current')))
        this.loading = false
      })
    },
  },

  computed: {

    formattedMetasDuplicates: function () {
      return this.duplicates.map((item) => {
        item['d'] = parseFloat(item['d']).toFixed(5)
        return item
      })
    },

  },
}
</script>

<style scoped>

</style>