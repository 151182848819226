<template>
  <v-card>
    <v-card-title>
      {{ $t('Metas Results') }}
      <v-spacer></v-spacer>
      <v-container fluid>
        <v-row dense>
          <v-col
              class="d-flex"
              cols="12"
              sm="6"
          >
            <SelectMetas @metas_selected="getDataItems"/>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6">
            <v-autocomplete
                auto-select-first
                clearable
                deletable-chips
                outlined
                dense
                chips
                small-chips
                multiple
                v-model="factor_filter_values"
                :items="language === 'en' ? factor_e_list : factor_d_list"
                :label="$t('Select Factor')"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="filteredList"
        :search="search"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 500, -1],
        }"
        :loading="loading"
        loading-text="Loading... Please wait"
    ></v-data-table>
  </v-card>
</template>

<script>
import {i18n} from "@/main.js";
import {apiService} from "../../common/apiService";
import SelectMetas from "../SelectMetas";

export default {

  name: "MetasOutput",

  components: {
    SelectMetas
  },

  data() {
    return {
      search: '',
      headers: [],
      metas_output: [],
      loading: true,
      factor_e_list: [],
      factor_d_list: [],
      factor_filter_values: [],
      language: i18n.locale,
    }
  },

  methods: {

    getDataItems(id) {
      this.metas_output = []
      this.loading = true
      let endpoint = "en/api/metas-output/" + id + "/"
      apiService(endpoint, 'get').then((response) => {
        this.headers = response.data.headers
        this.metas_output = response.data.result
        this.factor_e_list = response.data.factor_e_list
        this.factor_d_list = response.data.factor_d_list
        this.loading = false
      })
    },

  },

  computed: {

    filteredList: function () {
      if (i18n.locale === 'en') {
        return this.metas_output.filter((el) => {
          if (this.factor_filter_values.length === 0) return true;
          else return this.factor_filter_values.includes(el['Faktor_E'])
        })
      } else {
        return this.metas_output.filter((el) => {
          if (this.factor_filter_values.length === 0) return true;
          else return this.factor_filter_values.includes(el['Faktor_D'])
        })
      }
    },

  },

}
</script>

<style scoped>

</style>