<script>
    import {Scatter} from 'vue-chartjs'

    export default {
        name: 'ChartScatter',
        extends: Scatter,
        data: () => ({
            chartdata: {
                labels: ['Январь', 'Февраль', 'Март', 'Апрель'],
                datasets: [{
                    label: 'Scatter Dataset',
                    pointBackgroundColor: 'red',
                    data: [{
                        x: -10,
                        y: 0
                    }, {
                        x: 0,
                        y: 10
                    }, {
                        x: 10,
                        y: 5
                    }, {
                        x: 5,
                        y: 5
                    }, {
                        x: -4,
                        y: 2
                    }, {
                        x: 7,
                        y: -8
                    }, {
                        x: 10,
                        y: 1
                    }]
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        type: 'linear',
                        position: 'bottom'
                    }]
                }
            }
        }),

        mounted() {
            this.renderChart(this.chartdata, this.options)
        }
    }
</script>

<style scoped>

</style>