<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="dataset"
                sort-by="factor"
                class="elevation-1"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t("OutputDataSet") }}</v-toolbar-title>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:no-data>
                <!--            <v-btn color="primary" @click="getDataItems">Reset</v-btn>-->
                <p style="text-align: center;">Loading... Please wait</p>
            </template>
        </v-data-table>
<!--        <vue-speedometer :value="555"/>-->
    </div>
</template>

<script>
  import {i18n} from "@/main.js";
  import {apiService} from "../../common/apiService";
  // import VueSpeedometer from "vue-speedometer"

  export default {
    name: "OutputDataSet",

    // components: {VueSpeedometer},
  //   template: `
  //   <div>
  //     <vue-speedometer
  //       :needleHeightRatio="0.7"
  //       :maxSegmentLabels="5"
  //       :segments="1000"
  //       :value="333"
  //     />
  //   </div>
  // `,

    data: () => ({
      dialog: false,
      headers: [
        {
          text: 'id',
          value: 'id',
        },
        {
          text: i18n.t("Factor"),
          value: 'factor_' + i18n.locale,
        },
        {
          text: 'd',
          value: 'd',
        },
        {
          text: 'N-Metas',
          value: 'n_metas',
        },
        {
          text: i18n.t("N-Students"),
          value: 'n_students',
        },
        {
          text: 'Alter-Min',
          value: 'alter_min',
        },
        {
          text: 'Alter-Max',
          value: 'alter_max',
        },
      ],
      dataset: [],
    }),


    created() {
      this.getDataItems()
    },

    methods: {

      getDataItems() {
        let endpoint = "en/api/outputdataset/";
        apiService(endpoint).then((response) => {
          this.dataset = response.data;
        })
      },

    },
  }
</script>

<style scoped>

</style>