<template>
    <v-container fluid>
        <v-row dense>
            <v-col v-for="(item, index) in dataset"
                   :key="index"
                   cols="12"
                   sm="4"
            >
                <v-card class="text-center">
                    <v-card-title class="justify-center" v-if="$i18n.locale=='en'"
                                  v-text="item.factor_en"></v-card-title>
                    <v-card-title class="justify-center" v-else v-text="item.factor_de"></v-card-title>

                    <FusionAngularGauge :key="item.d" :dvalue="item.d"/>
                    <v-card-title class="justify-center" v-text="'d = '+item.d"></v-card-title>
                    <hr>
                    <FusionHLinearGauge :qvalue="item.q"/>
                    <v-card-title style="padding-top: 0" class="justify-center" v-text="'q = '+item.q"></v-card-title>
                </v-card>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
  import {apiService} from "../../common/apiService";
  import FusionAngularGauge from "../charts/FusionAngularGauge";
  import FusionHLinearGauge from "../charts/FusionHLinearGauge";

  export default {
    name: "ChartDQ",
    components: {
      FusionAngularGauge,
      FusionHLinearGauge
    },
    data: () => ({
      dialog: false,
      dataset: []
    }),
    methods: {
      getDataItems() {
        let endpoint = "en/api/outputdataset/";
        apiService(endpoint).then((response) => {
          this.dataset = response.data;
        })
      },
    },
    created() {
      this.getDataItems()
    }
  }
</script>
