<template>
  <v-select
      v-model="metas_selected"
      :items="metas_list"
      item-text="metas_title"
      item-value="id"
      :label="$t('Metas Input')"
      :placeholder="$t('Select Metas')"
      dense
      outlined
  ></v-select>
</template>

<script>
import {apiService} from "../common/apiService";

export default {

  name: "SelectMetas",

  data: () => ({
    metas_list: [],
    metas_selected: null,
    language: localStorage.getItem('lang'),
  }),

  watch: {
    metas_selected(val) {
      this.setMetasSelected(val)
    }
  },

  methods: {

    getMetasList() {
      let endpoint = "en/api/metaslist/";
      apiService(endpoint).then((response) => {
        this.metas_list = response.data
        if (localStorage.getItem('metas_current')) {
          this.metas_selected = parseInt(localStorage.getItem('metas_current'))
        } else {
          this.metas_selected = this.metas_list[0].id
        }
      })
    },

    setMetasSelected(id) {
      this.metas_selected = id
      localStorage.setItem("metas_current", this.metas_selected)
      this.$emit('metas_selected', this.metas_selected)
    },
  },

  created() {
    this.getMetasList()
  },

}
</script>

<style scoped>

</style>