<template>
    <v-container fluid>
        <v-row dense>
            <v-col
                    v-for="item in dataset"
                    v-bind:key="item.id"
                    cols="12"
                    sm="4"
            >
                <v-card style="text-align: center">
                    <v-card-title v-if="$i18n.locale=='en'" style="text-align: center" v-text="item.factor_en"></v-card-title>
                    <v-card-title v-else style="text-align: center" v-text="item.factor_de"></v-card-title>
                    <vue-speedometer :minValue="-0.2"
                                     :maxValue="1.0"
                                     :maxSegmentLabels="7"
                                     :segments="7"
                                     :value="item.d"
                                     :customSegmentStops="[-0.2, 0, 0.2, 0.4, 0.6, 0.8, 1]"
                                     :segmentColors='["red","tomato", "gold", "limegreen", "green", "blue"]'
                                     currentValueText="d = #{value}"
                                     currentValuePlaceholderStyle="#{value}"
                                     needleColor="lightgreen"
                                     :height="200"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
  // import {i18n} from "@/main.js";
  import {apiService} from "../../common/apiService";
  import VueSpeedometer from "vue-speedometer"

  export default {

    components: {VueSpeedometer},

    name: "ChartD",

    data: () => ({
      dialog: false,
      dataset: [],
    }),


    created() {
      this.getDataItems()
    },

    methods: {

      getDataItems() {
        let endpoint = "en/api/outputdataset/";
        apiService(endpoint).then((response) => {
          this.dataset = response.data;
        })
      },

    },
  }
</script>

<style scoped>

</style>