<template>
  <v-list dense>
    <template v-for="item in items">

      <v-list-group
          v-if="item.children"
          :key="item.text"
          :to="item.to"
          v-model="item.model"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon=""
      >
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.to ? '/' + $i18n.locale + child.to : null"
            link
        >
          <v-list-item-action v-if="child.icon">
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ child.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item v-else :key="item.text"
                   :to="item.to ? '/' + $i18n.locale + item.to : null"
                   link
      >
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import {i18n} from "@/main.js";

export default {
  name: "NavBar",
  data() {
    return {
      items: [
        // {icon: 'mdi-database-import', text: 'Input DataSet', to: '/InputDataSet'},
        // {
        //   icon: 'mdi-chevron-up',
        //   'icon-alt': 'mdi-chevron-down',
        //   text: 'Parameters',
        //   model: false,
        //   children: [
        //     {text: 'Domains', to: '/Domains'},
        //     {text: 'Factors', to: '/Factors'}
        //   ]
        // },
        {icon: 'mdi-database-import', text: i18n.t('Metas List'), to: '/MetasList'},
        {icon: 'mdi-database-import', text: i18n.t('Metas Input'), to: '/MetasInput'},
        {icon: 'mdi-database-import', text: i18n.t('Metas Duplicates'), to: '/MetasDuplicates'},
        {icon: 'mdi-database-export', text: i18n.t('Metas Results'), to: '/MetasOutput'},
        {icon: 'mdi-database-export', text: i18n.t('Factor Results'), to: '/FactorOutput'},
        {icon: 'mdi-database-export', text: i18n.t('Factor Describe'), to: '/FactorDescribe'},
        {icon: 'mdi-chart-areaspline', text: i18n.t('Barometer DQ'), to: '/BarometerDQ'},
        {icon: 'mdi-chart-areaspline', text: i18n.t('Thermometer DQ'), to: '/ThermometerDQ'},
        {icon: 'mdi-chart-areaspline', text: i18n.t('Thermometer Horizontal DQ'), to: '/ThermometerHorizontalDQ'},
        {icon: 'mdi-chart-bar-stacked', text: i18n.t('Charts'), to: '/ResultCharts'},
        // {icon: 'mdi-database-export', text: 'Output DataSet', to: '/OutputDataSet'},
        // {icon: 'mdi-database-export', text: 'Chart D', to: '/ChartD'},
        // {icon: 'mdi-database-export', text: 'Chart DQ', to: '/ChartDQ'},
        // {icon: 'mdi-chart-bar-stacked', text: 'Results Charts'},
        // {
        //   icon: 'mdi-chevron-up',
        //   'icon-alt': 'mdi-chevron-down',
        //   text: 'Charts Test Types',
        //   model: false,
        //   children: [
        //     {icon: 'mdi-chart-bar-stacked', text: 'ChartBar', to: '/ChartBar'},
        //     {icon: 'mdi-chart-bar-stacked', text: 'ChartLine', to: '/ChartLine'},
        //     {icon: 'mdi-chart-bar-stacked', text: 'ChartScatter', to: '/ChartScatter'},
        //     {icon: 'mdi-chart-bar-stacked', text: 'ChartRadar', to: '/ChartRadar'},
        //   ]
        // },
        {icon: 'mdi-logout', text: i18n.t('Logout'), to: '/Logout'},
      ],
    }
  },
}
</script>
