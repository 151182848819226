<template>
  <v-card>
    <v-card-title>
      {{ $t('Metas Input List') }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="formattedMetasList"
        sort-by="id"
        sort-desc
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>{{ $t("Edit metas list") }}</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                {{ $t("Upload new list") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <v-stepper v-model="e1">
                        <v-stepper-header>
                          <v-stepper-step
                              :complete="e1 > 1"
                              step="1"
                          >
                            {{ $t("Upload xls/xlsx file") }}
                          </v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step
                              :complete="e1 > 2"
                              step="2"
                          >
                            {{ $t("Validation") }}
                          </v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step step="3">
                            {{ $t("Save") }}
                          </v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items>
                          <v-stepper-content step="1">
                            <v-card
                                class="mb-12"
                                color="lighten-1"
                            >
                              <v-text-field v-model="editedItem.metas_title" :label="$t('Metas title')"></v-text-field>
                              <v-file-input
                                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  show-size
                                  :label="$t('File input')"
                                  value=""
                                  v-model="metas_input_file"
                              ></v-file-input>
                              <v-alert
                                  border="top"
                                  colored-border
                                  type="info"
                                  elevation="2"
                              >
                                {{ $t('Please download sample') }} <a target="_blank" href="/static/template.xlsx">{{ $t('data file') }}</a>.
                                {{ $t('The column names in the input file must match the sample file.') }}
                              </v-alert>
                            </v-card>

                            <v-btn
                                color="primary"
                                @click="validateMetasInputFile"
                            >
                              {{ $t('Validate') }}
                            </v-btn>

                            <v-btn
                                @click="close"
                                text
                            >
                              {{ $t('Cancel') }}
                            </v-btn>
                          </v-stepper-content>

                          <v-stepper-content step="2">
                            <v-card
                                class="mb-12"
                                color="lighten-1"
                                height="150px"
                            >
                              <v-alert
                                  v-if="next_step"
                                  border="top"
                                  colored-border
                                  type="success"
                                  elevation="2"
                              >
                                {{ metas_validate_error }}
                              </v-alert>
                              <v-alert
                                  v-else
                                  border="top"
                                  colored-border
                                  type="error"
                                  elevation="2"
                              >
                                {{ metas_validate_error }}
                              </v-alert>

                            </v-card>

                            <v-btn
                                v-if="next_step"
                                color="primary"
                                @click="checkDuplicates"
                            >
                              {{ $t('Calculate') }}
                            </v-btn>

                            <v-btn
                                @click="e1 = 1"
                                text
                            >
                              {{ $t('Cancel') }}
                            </v-btn>
                          </v-stepper-content>

                          <v-stepper-content step="3">
                            <v-card
                                class="mb-12"
                                color="lighten-1"
                                height="150px"
                            >
                              <v-alert
                                  v-if="next_step"
                                  border="top"
                                  colored-border
                                  type="success"
                                  elevation="2"
                              >
                                {{ metas_validate_error }}
                              </v-alert>
                              <v-alert
                                  v-else
                                  border="top"
                                  colored-border
                                  type="error"
                                  elevation="2"
                              >
                                {{ metas_validate_error }}
                              </v-alert>
                            </v-card>

                            <v-btn
                                v-if="next_step"
                                color="primary"
                                @click="save"
                            >
                              {{ $t('Save') }}
                            </v-btn>

                            <v-btn
                                @click="e1 = 2"
                                text
                            >
                              {{ $t('Cancel') }}
                            </v-btn>
                          </v-stepper-content>
                        </v-stepper-items>
                      </v-stepper>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  {{ $t('Cancel') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">{{ $t('Are you sure you want to delete this item?') }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{ $t('Cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="downloadItem(item)"
              :title="$t('Download results')"
          >
            mdi-download
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              :title="$t('Edit metas list')"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
              :title="$t('Delete list')"
          >
            mdi-delete
          </v-icon>
        </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {apiService} from "@/common/apiService";
import axios from "axios";
import {i18n} from "@/main";
import store from '@/store'

export default {

  name: "MetasList",

  data() {
    return {
      fData: '',
      e1: 1,
      dialog: false,
      dialogDelete: false,
      headers: [
        {text: 'Id', align: 'start', value: 'id'},
        {text: i18n.t('Metas title'), value: 'metas_title'},
        // {text: 'Metas Input', value: 'metas_input'},
        // {text: 'Metas Output', value: 'metas_output'},
        // {text: 'Factor Output', value: 'factor_output'},
        {text: i18n.t('Created'), value: 'created'},
        {text: i18n.t('Updated'), value: 'updated'},
        {text: i18n.t('Actions'), value: 'actions', sortable: false},
      ],
      editedIndex: -1,
      editedItem: {
        metas_title: '',
        metas_input: null,
      },
      defaultItem: {
        metas_title: '',
        metas_input: [],
      },
      metas_list: [],
      loading: true,
      metas_validate_error: '',
      metas_input_file: [],
      next_step: true,
      metas_results: '',
    }
  },

  computed: {
    userInfo: {
      get() {
        return JSON.parse(store.getters.userInfo);
      }
    },

    formTitle() {
      return this.editedIndex === -1 ? i18n.t('Upload new list') : i18n.t('Edit metas list')
    },

    formattedMetasList: function () {
      return this.metas_list.map((item) => {
        item.created = new Intl.DateTimeFormat('de-DE', {
          dateStyle: 'short',
          timeStyle: 'long'
        }).format(new Date(item.created))
        item.updated = new Intl.DateTimeFormat('de-DE', {
          dateStyle: 'short',
          timeStyle: 'long'
        }).format(new Date(item.updated))
        return item
      })
    },

  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.getDataItems()
  },

  methods: {

    getDataItems() {
      let endpoint = "en/api/metaslist/";
      apiService(endpoint).then((response) => {
        this.metas_list = response.data
        this.loading = false
      })
    },

    checkDuplicates() {
      let endpoint = "en/api/metas-check-duplicates/";
      let formData = new FormData()
      formData.append('metas_input', this.metas_input_file)
      formData.append('metas_id', this.editedItem.id ? this.editedItem.id : 0)
      axios.post(
          endpoint,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + localStorage.getItem('user-token')
            }
          }
      ).then((response) => {
        this.metas_validate_error = i18n.t('Duplicates count: ') + response.data + '.\n' + i18n.t('Validation Ok!')
        this.next_step = true
      }).catch(() => {
        this.metas_validate_error = i18n.t('Error calculate!')
        this.next_step = false
      })
      this.e1 = 3
    },

    validateMetasInputFile() {
      let endpoint = "en/api/metas-upload/";
      let formData = new FormData()
      formData.append('metas_input', this.metas_input_file)
      axios.post(
          endpoint,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + localStorage.getItem('user-token')
            }
          }
      ).then(() => {
        this.metas_validate_error = i18n.t('Validation Ok!')
        this.next_step = true
      }).catch(() => {
        this.metas_validate_error = i18n.t('Error input file columns!')
        this.next_step = false
      })
      this.e1 = 2
    },

    downloadItem(item) {
      this.editedIndex = this.metas_list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      let endpoint = "en/api/metas-results-download/" + this.editedItem.id + "/";
      axios({
        url: endpoint,
        method: 'GET',
        responseType: 'blob',
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('user-token')
        }
      }).then((response) => {
        let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'export.xlsx'
        link.click()
      })
    },

    editItem(item) {
      this.editedIndex = this.metas_list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.metas_list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      let endpoint = "en/api/metaslist/" + this.editedItem.id + "/";
      apiService(endpoint, 'delete').then(() => {
        this.getDataItems()
      })
      this.closeDelete()
    },

    close() {
      this.e1 = 1
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.loading = true
      let formData = new FormData()
      formData.append('metas_input', this.metas_input_file)
      formData.append('owner', this.userInfo.pk)
      formData.append('metas_title', this.editedItem.metas_title)
      formData.append('enabled', true)
      if (this.editedIndex > -1) {
        let endpoint = "en/api/metaslist/" + this.editedItem.id + "/";
        formData.append('metas_id', this.editedItem.id)
        Object.assign(this.metas_list[this.editedIndex], this.editedItem)
        axios.patch(
            endpoint,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + localStorage.getItem('user-token')
              }
            }
        ).then(() => {
          this.getDataItems()
        })
      } else {
        let endpoint = "en/api/metaslist/";
        axios.post(
            endpoint,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + localStorage.getItem('user-token')
              }
            }
        ).then(() => {
          this.getDataItems()
        })
      }
      this.close()
    },

  },

}
</script>

<style scoped>

</style>