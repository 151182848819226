import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {AUTH_LOGOUT} from "@/store/actions/auth"
import {i18n} from "@/main.js";


function apiService(endpoint, method = "get", data='') {

  // let datalist = {}
  const base = {
    baseURL: "/",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('user-token')
    },
    xhrFields: {
      withCredentials: true
    }
  }
  const axiosInstance = axios.create(base)

  axiosInstance.interceptors.response.use(undefined, (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(AUTH_LOGOUT)
        .then(() => {
          router.push('/' + i18n.locale + '/Login')
        })
    }
    return Promise.reject(error.response.data);
  });

  return axiosInstance({
    url: endpoint,
    method: method,
    params: {},
    data: data
  })

}

export {apiService};
