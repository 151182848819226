<template>
    <div>
        <fusioncharts
                :type="type"
                :width="width"
                :height="height"
                :dataFormat="dataFormat"
                :dataSource="dataSource"
                ref="fc"
        ></fusioncharts>
    </div>
</template>
<script>

import Vue from 'vue';
import VueFusionChartsComponent from 'vue-fusioncharts/component';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import AngularGauge from 'fusioncharts/fusioncharts.widgets';
import Thermometer from 'fusioncharts/fusioncharts.widgets';
import Candy from 'fusioncharts/themes/fusioncharts.theme.candy';
import Gammel from 'fusioncharts/themes/fusioncharts.theme.gammel';

const vueFusionCharts = VueFusionChartsComponent(FusionCharts, Charts, AngularGauge, Thermometer, Candy, Gammel);

Vue.component('fusioncharts', vueFusionCharts);


export default {

    name: "FusionThermometer",

    props: {
        dvalue: {
            type: Number
        },
        q_interpretation: {
            type: String
        }
    },

    computed: {
        currentTheme: function () {
            return this.$vuetify.theme.dark ? 'candy' : 'gammel'
        }
    },

    watch: {
        currentTheme() {
            this.onThemeChanged()
        },
    },

    methods: {
        onThemeChanged: function () {
            this.$refs.fc.chartObj.setChartAttribute("theme", this.currentTheme);
            this.$refs.fc.chartObj.setChartAttribute("bgColor", this.currentTheme === 'candy' ? '#1e1e1e' : '#ffffff');
        },

        setDValues() {
            this.dataSource.value = this.dvalue;
            if (this.dvalue <= 0) {
                this.dataSource.chart.thmFillColor = "#0000ff";
            } else if (this.dvalue <= 0.4) {
                this.dataSource.chart.thmFillColor = "#00ff00";
            } else {
                this.dataSource.chart.thmFillColor = "#ff0000";
            }
        }
    },

    created() {
        this.setDValues()
    },

    data: () => ({
        type: "thermometer",
        width: "240",
        height: "400",
        dataFormat: "json",
        dialog: false,
        dataSource: {
            chart: {
                lowerLimit: "-1",
                upperLimit: "2",
                theme: localStorage.getItem('dark') === 'true' ? 'candy' : 'gammel',
                decimals: "3",
                showhovereffect: "1",
                thmFillColor: "#008ee4",
                showGaugeBorder: "1",
                gaugeBorderColor: "#008ee4",
                gaugeBorderThickness: "2",
                gaugeBorderAlpha: "30",
                thmOriginX: "100",
                chartBottomMargin: "20",
                valueFontColor: "#000000",
                tickMarkDistance: "5",
                tickValueDistance: "2",
                majorTMNumber: "5",
                majorTMHeight: "12",
                minorTMNumber: "9",
                minorTMHeight: "7",
                tickValueStep: "0.1",
                showvalue: false
            },
            value: 1,
        }
    }),

}
</script>

<style>
text[y="193"] {
    display: none;
}

svg > g[class^="raphael-group-"] > text {
    display: none;
}
</style>