<template>
  <v-card>
    <v-card-title>
      {{ $t('Metas Input') }}
      <v-spacer></v-spacer>

    </v-card-title>
    <v-data-table
        height="500"
        :headers="headers"
        :items="filteredMetasInput"
        :search="search"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 500, -1],
        }"
        :loading="loading"
        loading-text="Loading... Please wait"
        fixed-header
        v-model="selected_rows"
        show-select
    >
      <template
          v-slot:top
      >
        <v-container fluid>
          <v-row dense>
            <v-col
                class="d-flex"
                cols="12"
                sm="4"
            >
              <SelectMetas @metas_selected="getDataItems"/>
            </v-col>
            <v-col
                class="d-flex"
                cols="12"
                sm="4"
            >
              <v-autocomplete
                  auto-select-first
                  clearable
                  deletable-chips
                  outlined
                  dense
                  chips
                  small-chips
                  multiple
                  v-model="factor_filter_values"
                  :items="language === 'en' ? factor_e_list : factor_d_list"
                  :label="$t('Select Factor')"
              ></v-autocomplete>
            </v-col>
            <v-col
                class="d-flex"
                cols="12"
                sm="4"
            >
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('Search')"
                  outlined
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn :disabled="!selected_rows.length" color="success" class="mb-2 mr-2" @click="deleteMetas">{{ $t("Delete selected") }}</v-btn>
              <v-btn color="success" :disabled="!canSave" class="mb-2 mr-2" @click="saveMetas">{{ $t("Save") }}</v-btn>
              <v-btn color="warning" :disabled="!canCancel" class="mb-2 mr-2" @click="cancelMetas">{{ $t("Cancel") }}</v-btn>
            </v-col>
            <v-col>
              <v-dialog v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2 float-right" v-on="on">{{ $t("New Item") }}</v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                :rules="[rules.required]"
                                v-model="editedItem['Domäne_E']"
                                :label="$t('Domäne_E')">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                :rules="[rules.required]"
                                v-model="editedItem['Domäne_D']"
                                :label="$t('Domäne_D')"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                :rules="[rules.required]"
                                v-model="editedItem['Faktor_E']"
                                :label="$t('Faktor_E')">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                :rules="[rules.required]"
                                v-model="editedItem['Faktor_D']"
                                :label="$t('Faktor_D')">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                :rules="[rules.required]"
                                v-model="editedItem['VL']"
                                :label="$t('VL')">
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                :rules="[rules.required]"
                                v-model="editedItem['Autor']"
                                :label="$t('Autor')"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                maxlength="4"
                                type="number"
                                v-model="editedItem['Jahr']"
                                :label="$t('Jahr')"
                                :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                type="number"
                                v-model="editedItem['N-Metas']"
                                :label="$t('N-Metas')"
                                :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                type="number"
                                v-model="editedItem['N-Effekte']"
                                :label="$t('N-Effekte')"
                                :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                type="number"
                                v-model="editedItem['d']"
                                label="d"
                                :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="editedItem['Beschreibung']"
                                :label="$t('Beschreibung')"
                                :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{ $t('Cancel') }}</v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                        :disabled="!valid"
                    >
                      {{ $t('Save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">{{ $t('Are you sure you want to delete this item?') }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">{{ $t('Cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {i18n} from "@/main.js";
import {apiService} from "../../common/apiService";
import SelectMetas from "../SelectMetas";

export default {

  name: "MetasInput",

  components: {
    SelectMetas
  },

  data() {
    return {
      search: '',
      headers: [
        {text: 'id', align: 'start', value: 'id'},
        {text: i18n.t('Domäne_E'), value: 'Domäne_E'},
        {text: i18n.t('Domäne_D'), value: 'Domäne_D'},
        {text: i18n.t('Faktor_E'), value: 'Faktor_E'},
        {text: i18n.t('Faktor_D'), value: 'Faktor_D'},
        {text: i18n.t('VL'), value: 'VL'},
        {text: i18n.t('Autor'), value: 'Autor'},
        {text: i18n.t('Jahr'), value: 'Jahr'},
        {text: i18n.t('N-Metas'), value: 'N-Metas'},
        {text: i18n.t('N-Effekte'), value: 'N-Effekte'},
        {text: i18n.t('d'), value: 'd'},
        {text: i18n.t('Beschreibung'), value: 'Beschreibung'},
        {text: i18n.t('Actions'), value: 'actions', sortable: false},
      ],
      metas_input: [],
      selected_rows: [],
      loading: true,
      factor_filter_values: [],
      factor_e_list: [],
      factor_d_list: [],
      language: i18n.locale,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        'id': '',
        'Domäne_E': '',
        'Domäne_D': '',
        'Faktor_E': '',
        'Faktor_D': '',
        'VL': '',
        'Autor': '',
        'Jahr': 0,
        'N-Metas': 0,
        'N-Effekte': 0,
        'd': 0,
        'Beschreibung': '',
      },
      defaultItem: {
        'id': '',
        'Domäne_E': '',
        'Domäne_D': '',
        'Faktor_E': '',
        'Faktor_D': '',
        'VL': '',
        'Autor': '',
        'Jahr': 0,
        'N-Metas': 0,
        'N-Effekte': 0,
        'd': 0,
        'Beschreibung': '',
      },
      canSave: false,
      canCancel: false,
      rules: {
        required: value => !!value || 'Required.',
      },
      valid: true,
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {
    getDataItems(id) {
      this.factor_filter_values = []
      this.metas_input = []
      this.loading = true
      let endpoint = "en/api/metas-input-get/" + id + "/"
      apiService(endpoint, 'get').then((response) => {
        this.metas_input = response.data.result
        this.factor_e_list = response.data.factor_e_list
        this.factor_d_list = response.data.factor_d_list
        this.loading = false
      })
    },

    editItem(item) {
      this.editedIndex = this.metas_input.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.metas_input.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.metas_input.splice(this.editedIndex, 1)
      this.closeDelete()
      this.canSave = true
      this.canCancel = true
    },

    close() {
      this.dialog = false
      this.valid = true
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          Object.assign(this.metas_input[this.editedIndex], this.editedItem)
        } else {
          this.metas_input.push(this.editedItem)
        }
        this.close()
        this.canSave = true
        this.canCancel = true
      }
    },

    saveMetas() {
      this.loading = true
      let endpoint = "en/api/metas-input-post/" + parseInt(localStorage.getItem('metas_current')) + "/"
      apiService(endpoint, 'post', {
        metas_input_edited: this.metas_input
      }).then(() => {
        this.getDataItems(parseInt(localStorage.getItem('metas_current')))
        this.loading = false
      })

      this.canSave = false
      this.canCancel = false
      // this.$refs.form.reset()
    },

    cancelMetas() {
      this.getDataItems(parseInt(localStorage.getItem('metas_current')))
      this.canSave = false
      this.canCancel = false
    },

    deleteMetas() {
      this.selected_rows.map((item) => {
        let editedIndex = this.metas_input.indexOf(item)
        this.metas_input.splice(editedIndex, 1)
      })
      this.selected_rows = []
      this.canSave = true
      this.canCancel = true
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$i18n.t('New Item') : this.$i18n.t('Edit Item')
    },
    formattedMetasInput: function () {
      return this.metas_input.map((item) => {
        item['d'] = parseFloat(item['d']).toFixed(5)
        return item
      })
    },
    filteredMetasInput: function () {
      return this.formattedMetasInput.filter(d => {
        return this.factor_filter_values.length < 1 ||
            this.factor_filter_values.includes(d['Faktor_E']) ||
            this.factor_filter_values.includes(d['Faktor_D'])
      })
    },
  },

}
</script>

<style scoped>

</style>