import Vue from "vue"
import Router from "vue-router"
import store from '@/store'
import {i18n} from "@/main.js"

import Login from '@/components/pages/Login'
import Logout from '@/components/pages/Logout'
import MetasList from "@/components/pages/MetasList";
import MetasInput from "../components/pages/MetasInput";
import MetasDuplicates from "../components/pages/MetasDuplicates";
import MetasOutput from "@/components/pages/MetasOutput";
import FactorOutput from "@/components/pages/FactorOutput";
import FactorDescribe from "../components/pages/FactorDescribe";
import BarometerDQ from "@/components/pages/BarometerDQ";
import ThermometerDQ from "@/components/pages/ThermometerDQ";
import ThermometerHorizontalDQ from "@/components/pages/ThermometerHorizontalDQ";
import ResultCharts from "../components/pages/ResultCharts";
import InputDataSet from '@/components/old/InputDataSet'
import OutputDataSet from "@/components/old/OutputDataSet";
import ChartD from "@/components/old/ChartD";
import ChartDQ from "@/components/old/ChartDQ";
import Factors from "@/components/old/Factors"
import Domains from "@/components/old/Domains"
import ChartBar from '@/components/old/ChartBar'
import ChartLine from '@/components/old/ChartLine'
import ChartScatter from '@/components/old/ChartScatter'
import ChartRadar from '@/components/old/ChartRadar'

import RouterView from "@/components/RouterView.vue"


Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/' + i18n.locale + '/')
}

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/' + i18n.locale + '/Login')
}


export default new Router({
    mode: "history",
    base: "/",
    routes: [
        {
            path: "/:lang",
            component: RouterView,
            beforeEnter(to, from, next) {
                const lang = to.params.lang;
                if (!["en", "de"].includes(lang)) return next("en");
                if (i18n.locale !== lang) {
                    i18n.locale = lang;
                }
                return next();
            },
            children: [
                {
                    path: "/",
                    name: "MetasList",
                    component: MetasList,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "MetasInput",
                    name: "MetasInput",
                    component: MetasInput,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "MetasDuplicates",
                    name: "MetasDuplicates",
                    component: MetasDuplicates,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "MetasOutput",
                    name: "MetasOutput",
                    component: MetasOutput,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "FactorOutput",
                    name: "FactorOutput",
                    component: FactorOutput,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "FactorDescribe",
                    name: "FactorDescribe",
                    component: FactorDescribe,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "BarometerDQ",
                    name: "BarometerDQ",
                    component: BarometerDQ,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "ThermometerDQ",
                    name: "ThermometerDQ",
                    component: ThermometerDQ,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "ThermometerHorizontalDQ",
                    name: "ThermometerHorizontalDQ",
                    component: ThermometerHorizontalDQ,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "ResultCharts",
                    name: "ResultCharts",
                    component: ResultCharts,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "InputDataSet",
                    name: "InputDataSet",
                    component: InputDataSet,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "OutputDataSet",
                    name: "OutputDataSet",
                    component: OutputDataSet,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "ChartD",
                    name: "ChartD",
                    component: ChartD,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "ChartDQ",
                    name: "ChartDQ",
                    component: ChartDQ,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "Factors",
                    name: "Factors",
                    component: Factors,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "Domains",
                    name: "Domains",
                    component: Domains,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "Login",
                    name: "Login",
                    component: Login,
                    beforeEnter: ifNotAuthenticated,
                },
                {
                    path: "ChartBar",
                    name: "ChartBar",
                    component: ChartBar,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "ChartLine",
                    name: "ChartLine",
                    component: ChartLine,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "ChartScatter",
                    name: "ChartScatter",
                    component: ChartScatter,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "ChartRadar",
                    name: "ChartRadar",
                    component: ChartRadar,
                    beforeEnter: ifAuthenticated,
                },
                {
                    path: "Logout",
                    name: "Logout",
                    component: Logout,
                    beforeEnter: ifAuthenticated,
                }
            ]
        },
        {
            path: "*",
            redirect: "/en"
        }
    ]
});