<template>
  <v-card>
    <v-card-title>
      {{ $t('Factor Results') }}
      <v-spacer></v-spacer>
      <v-container fluid>
        <v-row dense>
          <v-col
              class="d-flex"
              cols="12"
              sm="6"
          >
            <SelectMetas @metas_selected="getDataItems"/>
          </v-col>
          <v-col
              class="d-flex"
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                outlined
                dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="formattedFactorList"
        :search="search"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 500, -1],
        }"
        :loading="loading"
        loading-text="Loading... Please wait"
    ></v-data-table>
  </v-card>
</template>

<script>
import {apiService} from "@/common/apiService";
import {i18n} from "../../main";
import SelectMetas from "../SelectMetas";

export default {

  name: "FactorOutput",

  components: {
    SelectMetas
  },

  data() {
    return {
      search: '',
      headers: [],
      factor_output: [],
      loading: true,
      language: localStorage.getItem('lang'),
    }
  },

  computed: {
    formattedFactorList: function () {
      return this.factor_output.map((item) => {
        item['d'] = parseFloat(item['d'].toFixed(2))
        item['d-Gewichtet'] = parseFloat(item['d-Gewichtet'].toFixed(2))
        item['q'] = parseFloat(item['q'].toFixed(2))
        if (this.language === 'de') {
          item['d-Interpretation'] = i18n.t(item['d-Interpretation'])
          item['q-Interpretation'] = i18n.t(item['q-Interpretation'])
        }
        return item
      })
    },
  },

  methods: {
    getDataItems(id) {
      this.factor_output = []
      this.loading = true
      let endpoint = "en/api/factor-output/" + id + "/"
      apiService(endpoint).then((response) => {
        this.headers = response.data.headers
        this.factor_output = response.data.result
        this.loading = false
      })
    },
  },

}
</script>

<style scoped>

</style>