<template>
  <v-container grid-list-md>
    <v-layout row wrap align-center justify-center fill-height>
      <v-flex xs12 sm8 lg4 md5>
        <v-card class="login-card">
          <v-card-title>
            <span class="headline">{{ $t("Login") }}</span>
          </v-card-title>

          <v-spacer/>

          <v-card-text>

            <v-layout
                row
                fill-height
                justify-center
                align-center
                v-if="loading"
            >
              <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
              />
            </v-layout>


            <v-form v-else ref="form" v-model="valid" lazy-validation @submit.prevent="login">
              <v-container>

                <v-text-field
                    v-model="username"
                    :counter="70"
                    :label="$t('username')"
                    :rules="rules.username"
                    maxlength="70"
                    required
                />

                <v-text-field
                    type="password"
                    v-model="password"
                    :counter="20"
                    :label="$t('password')"
                    :rules="rules.password"
                    maxlength="20"
                    required
                />

              </v-container>
              <v-btn class="pink white--text" :disabled="!valid" type="submit">{{ $t("Login") }}</v-btn>

            </v-form>


          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {i18n} from "@/main";
import Swal from 'sweetalert2'
import {AUTH_REQUEST} from '@/store/actions/auth'

export default {
  name: 'Login',
  data() {
    return {
      valid: true,
      loading: false,
      username: '',
      password: '',
      rules: {
        username: [
          v => !!v || i18n.t("Username is required"),
          v => (v && v.length > 3) || i18n.t("A username must be more than 3 characters long"),
          v => /^[a-z0-9_]+$/.test(v) || i18n.t("A username can only contain letters and digits")
        ],
        password: [
          v => !!v || i18n.t("Password is required"),
          v => (v && v.length > 7) || i18n.t("The password must be longer than 7 characters")
        ]
      },
    }
  },
  methods: {
    login: function () {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const {username, password} = this

        this.$store.dispatch(AUTH_REQUEST, {username, password}).then(() => {
          this.$router.push("/" + this.$i18n.locale + "/")
        }).catch(e => {
          Swal.fire({
            title: i18n.t('Username or password incorrect'),
            text: e,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: i18n.t('Try again!')
          })
          this.loading = false;
        })
      }
    }
  },
}
</script>