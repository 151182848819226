<template>
  <v-data-table
      :headers="headers"
      :items="domains"
      sort-by="domain_en"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ $t("Domains") }}</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">{{ $t("New Item") }}</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.domain_en" label="domain EN"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.domain_de" label="domain DE"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!--            <v-btn color="primary" @click="getDataItems">Reset</v-btn>-->
      <p style="text-align: center;">Loading... Please wait</p>
    </template>
  </v-data-table>
</template>

<script>
import {i18n} from "@/main.js";
import {apiService} from "../../common/apiService";

export default {
  name: "Domains",
  data: () => ({
    dialog: false,
    headers: [
      {
        text: 'id',
        value: 'id',
      },
      {
        text: i18n.t("domain EN"),
        value: 'domain_en',
      },
      {
        text: i18n.t("domain DE"),
        value: 'domain_de',
      },
      {text: 'Actions', value: 'action', sortable: false},
    ],
    domains: [],
    userid: 0,
    editedIndex: -1,
    editedItem: {
      domain_en: '',
      domain_de: '',
    },
    defaultItem: {
      domain_en: '',
      domain_de: '',
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$i18n.t('New Item') : this.$i18n.t('Edit Item')
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.getDataItems()
    this.getUserId()
  },

  methods: {
    getUserId() {
      let endpoint = "en/api/rest-auth/user/";
      apiService(endpoint).then((response) => {
        // eslint-disable-next-line no-console
        console.log(response.data.pk)
        this.userid = response.data.pk;
      })
    },

    getDataItems() {
      let endpoint = "en/api/domains/";
      apiService(endpoint).then((response) => {
        this.domains = response.data;
      })
    },

    editItem(item) {
      this.editedIndex = this.domains.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.dialog = true
    }
    ,

    deleteItem(item) {
      const index = this.domains.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.domains.splice(index, 1)

      let endpoint = "en/api/domains/" + item.id + "/";
      apiService(endpoint, 'delete').then((response) => {
        // eslint-disable-next-line no-console
        console.log(response)
        this.getDataItems()
      })

    }
    ,

    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    }
    ,

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.domains[this.editedIndex], this.editedItem)

        let endpoint = "en/api/domains/" + this.editedItem.id + "/";
        apiService(endpoint, 'patch', {
          domain: this.editedItem.domain_en,
          domain_en: this.editedItem.domain_en,
          domain_de: this.editedItem.domain_de,
        }).then((response) => {
          // eslint-disable-next-line no-console
          console.log(response)
          this.getDataItems()
        })

      } else {
        this.domains.push(this.editedItem)

        let endpoint = "en/api/domains/";
        apiService(endpoint, 'post', {
          domain: this.editedItem.domain_en,
          domain_en: this.editedItem.domain_en,
          domain_de: this.editedItem.domain_de,
          owner: this.userid,
        }).then((response) => {
          // eslint-disable-next-line no-console
          console.log(response)
          // eslint-disable-next-line no-console
          console.log(localStorage.getItem('user-name'))
          this.getDataItems()
        })

      }
      this.close()
    }
    ,
  },
}
</script>

<style scoped>

</style>