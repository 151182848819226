<template>
  <v-container fluid>
    <v-row dense>
      <h3>Thermometer Horizontal DQ</h3>
    </v-row>
    <v-row dense>
      <v-col
          class="d-flex"
          cols="12"
          sm="6"
      >
        <SelectMetas @metas_selected="getDataItems"/>
      </v-col>
      <v-col
          class="d-flex"
          cols="12"
          sm="6"
      >
        <v-autocomplete
            clearable
            deletable-chips
            outlined
            dense
            chips
            small-chips
            multiple
            v-model="factor_filter_values"
            :items="language === 'en' ? factor_e_list : factor_d_list"
            :label="$t('Select Factor')"
            auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense v-if="factor_filter_values">
      <v-col v-for="(item, index) in filteredList"
             :key="index"
             cols="12"
             sm="12"
             class="my-4"
      >
        <v-card
            :id="'barometr-'+index.toString()"
            class="text-center barometer-card">
          <v-row>
            <v-col cols="6">
              <div class="title-domain justify-center" v-if="language==='en'">
                {{ item['Domäne_E'] }}
              </div>
              <div class="title-domain justify-center" v-else>
                {{ item['Domäne_D'] }}
              </div>
              <v-card-title class="justify-center item-title" v-if="language==='en'"
                            v-text="item.Faktor_E"></v-card-title>
              <v-card-title class="justify-center item-title" v-else
                            v-text="item.Faktor_D"></v-card-title>

              <div class="title-rang pt-0">{{ $i18n.t('Rang') }}:<span
                  class="inter-dq">{{ item['rang'] }}/{{ factor_e_list.length }}</span></div>
              <div style="position: relative;">
                <FusionHLinearGauge :key="item['d-Gewichtet']"
                                    :dvalue="item['d-Gewichtet']"
                                    :q_interpretation="item['q-css-class']"
                                    style="padding-left: 20px;"
                />
                <div class="dv-title">d</div>
                <div class="dv">{{ item['d-Gewichtet'] }}</div>
              </div>
<!--              <div class="title-dq effect-size justify-center">{{ $i18n.t('Effect size') }}:-->
<!--                <v-card-subtitle class="justify-center effect-size"-->
<!--                                 v-text="'d = '+item['d-Gewichtet']"></v-card-subtitle>-->
<!--              </div>-->

            </v-col>
            <v-col cols="6" class="f-center">
              <div class="f-center pt-16 pl-10">
                <div class="title-dq f-items b-b-b pt-0">{{ $i18n.t('Meaningfulness') }}:<span
                    class="inter-dq">{{ item['q-Interpretation'] }}</span></div>
                <div class="title-dq f-items pt-0">{{ $i18n.t('Konfidenzintervall') }}:<span
                    class="inter-dq">± {{
                    parseFloat(item['Konfidenzintervall'].toFixed(2))
                  }}</span></div>
                <div class="title-dq f-items pt-0">{{ $i18n.t('N-Metas') }}:<span
                    class="inter-dq">{{ item['N-Metas'] }}</span></div>
                <div class="title-dq f-items pt-0">{{ $i18n.t('N-Studien') }}:<span
                    class="inter-dq">{{ item['N-Studien'] }}</span></div>
                <div class="title-dq f-items pt-0">{{ $i18n.t('Alter-Min') }}:<span
                    class="inter-dq">{{ item['Alter-Min'] }}</span></div>
                <div class="title-dq f-items pt-0">{{ $i18n.t('Alter-Max') }}:<span
                    class="inter-dq">{{ item['Alter-Max'] }}</span></div>
                <div class="title-dq f-items pt-0">{{ $i18n.t('Mittelwert Alter') }}:<span
                    class="inter-dq">{{ parseFloat(item['Mittelwert Alter'].toFixed(2)) }}</span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
                icon
                class="barometer-to-png"
                @click="getPngFromBarometer(id='barometr-'+index.toString(), name=item.Faktor_E)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as htmlToImage from "html-to-image";
import {apiService} from "../../common/apiService";
import FusionHLinearGauge from "../charts/FusionHLinearGauge.vue";
import {i18n} from "../../main";
import SelectMetas from "../SelectMetas";

export default {

  name: "ThermometerHorizontalDQ",

  components: {
    FusionHLinearGauge,
    SelectMetas
  },

  data: () => ({
    dialog: false,
    factor_output: [],
    factor_output_sorted: [],
    factor_filter_values: [],
    factor_e_list: [],
    factor_d_list: [],
    language: localStorage.getItem('lang'),
    d_css_class: {
      "harms": "harms",
      "doesn't affect": "d-affect",
      "affects little": "affects-little",
      "affects good": "affects-good",
      "very good": "very-good",
      "excellent": "excellent",
    },
    q_css_class: {
      "rather small": "rather-small",
      "acceptable": "acceptable",
      "high": "high",
      "very high": "very-high",
    },
  }),

  methods: {

    sortByField(field) {
      return (a, b) => a[field] < b[field] ? 1 : -1;
    },

    getDataItems(id) {
      this.factor_filter_values = []
      this.factor_e_list = []
      this.factor_d_list = []
      let endpoint = "en/api/factor-output/" + id + "/";
      apiService(endpoint).then((response) => {
        this.factor_filter_values.push(this.language === 'en' ? response.data.factor_e_list[0] : response.data.factor_d_list[0])
        this.factor_output = response.data.result
        this.factor_output_sorted = this.factor_output.sort(this.sortByField('d-Gewichtet'))
        const hhh = this.factor_output_sorted
        const addNew = (arr) => {
          const copyArr = [...arr];
          copyArr.forEach((el, index) => {
            el["rang"] = index + 1;
          });
          return copyArr;
        };
        addNew(hhh)
        this.factor_output = this.factor_output_sorted
        this.factor_e_list = response.data.factor_e_list
        this.factor_d_list = response.data.factor_d_list
      })

    },

    getPngFromBarometer(id, name) {
      const node = document.getElementById(id);
      const node_footer = node.querySelector('.v-card__actions');
      node.removeChild(node_footer);
      htmlToImage
          .toBlob(node, {
            pixelRatio: 4,
            skipAutoScale: true,
          })
          .then(function (blob) {
            node.appendChild(node_footer);
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = "Barometer_" + name + ".png";
            a.click();
          });
    },

  },

  computed: {

    filteredList: function () {
      if (this.language === 'en') {
        return this.formattedFactorList.filter((el) => {
          if (this.factor_filter_values.length === 0) return false;
          else return this.factor_filter_values.includes(el['Faktor_E'])
        })
      } else {
        return this.formattedFactorList.filter((el) => {
          if (this.factor_filter_values.length === 0) return false;
          else return this.factor_filter_values.includes(el['Faktor_D'])
        })
      }
    },

    formattedFactorList: function () {
      return this.factor_output.map((item) => {
        item['d'] = parseFloat(item['d'].toFixed(2))
        item['d-Gewichtet'] = parseFloat(item['d-Gewichtet'].toFixed(2))
        item['q'] = parseFloat(item['q'].toFixed(2))
        item['d-css-class'] = this.d_css_class[item['d-Interpretation']]
        item['q-css-class'] = this.q_css_class[item['q-Interpretation']]
        if (this.language === 'de') {
          item['d-Interpretation'] = i18n.t(item['d-Interpretation'])
          item['q-Interpretation'] = i18n.t(item['q-Interpretation'])
        }
        return item
      })
    },

  },

}
</script>
<style scoped>
h3 {
  padding-bottom: 30px;
  font-weight: normal;
}

.v-card__title {
  font-size: 16px;
}

.v-card__title.item-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  /*min-height: 72px;*/
  word-break: break-word;
  padding: 0.5rem;
}

.q-grafic {
  padding: 20px 10px;
}

.q-grafic-item {
  width: 25%;
  padding: 10px 0px;
  display: inline-block;
}

.inter-dq {
  font-size: 16px;
  padding: 3px 10px;
}

.title-dq.effect-size {
  font-weight: bold;
}

.v-card__subtitle.effect-size {
  font-size: 1rem;
  padding: 0.5rem;
}

/* d-Interpretation */
.harms {
  background: #7E5F5E;
}

.d-affect {
  background: #A36463;
}

.affects-little {
  background: #DA9997;
}

.affects-good {
  background: #C7D8A2;
}

.very-good {
  background: #A0B086;
}

.excellent {
  background: #727E60;
}

/* q-Interpretation */
.rather-small {
  background: #f2e4e3;
}

.acceptable {
  background: #e7edd6;
}

.high {
  background: #92a463;
}

.very-high {
  background: #5e6e36;
}

.q-grafic-item {
  color: #222222;
  font-size: 14px;
}

.q-grafic-item.active {
  border: 2px solid #222222;
  font-weight: bold;
}

.f-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}

.b-b-b {
  font-weight: bold;
  border-bottom: 2px solid;
}

.f-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-end;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title-domain {
  padding-top: 1rem;
  font-style: italic;
  color: #555;
}
.dv-title {
  position: absolute;
  top: 37%;
  left: 70px;
  font-style: italic;
  font-size: 18px;
  font-weight: bold;
}
.dv {
  position: absolute;
  top: 50%;
  left: 55px;
  width: 38px;
}
</style>