<i18n>
    {
    "en": {
    "ChartBar1": "hello world!"
    },
    "de": {
    "ChartBar1": "ChartBarenen"
    }
    }
</i18n>
<script>
  import {Bar} from 'vue-chartjs'
  import {i18n} from "@/main.js";

  export default {
    name: 'ChartBar',
    extends: Bar,
    data: () => ({
      chartdata: {
        labels: ['Январь', 'Февраль', 'Март', 'Апрель'],
        datasets: [
          {
            label: i18n.t("ChartBar1"),
            backgroundColor: '#f87979',
            data: [40, 20, 60, 10, 5, 80]
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    }),

    mounted() {
      this.renderChart(this.chartdata, this.options)
    }
  }
</script>

<style scoped>

</style>